import React from "react"
import { FaBars } from "react-icons/fa"
import { Link } from "gatsby"

import Links from "../constants/links"

const Navbar = ({ toggle }) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/" className="nav-logo">
            Wayne Blog
          </Link>
          <button className="toggle-btn" onClick={toggle}>
            <FaBars />
          </button>
          <Links styleClass={'nav-links'} />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
