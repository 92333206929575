import * as React from "react"
import { css } from "@emotion/react"

import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div css={globalWrapper}>
        <Sidebar toggle={toggle} isOpen={isOpen} />
        <Navbar toggle={toggle} />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

const globalWrapper = css`
  margin: var(--spacing-0) auto;
  max-width: var(--maxWidth-wrapper);
  padding: var(--spacing-12);
`

export default Layout
