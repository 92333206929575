import React from 'react'
import { css } from '@emotion/react'

const Footer = () => {
  return (
    <footer css={Wrapper}>
    <div>
      <p>&copy;{new Date().getFullYear()} wayne</p>
    </div>
  </footer>
  )
}
const Wrapper = css`
  height: 7rem;
  text-align: center;
  display: grid;
  place-content: center;
  p {
    margin-bottom: 0;
    text-transform: uppercase;
  }
`

export default Footer
