import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import Banner from "../components/Banner"
import CategoryTag from "../components/CategoryTag"

export const query = graphql`
  {
    allMarkdownRemark {
      distinct(field: frontmatter___category)
    }
  }
`

const CategoriesPage = ({ data }) => {
  const categoryTags = data.allMarkdownRemark.distinct

  return (
    <>
      <Layout>
        <Wrapper>
          <div className="section-center">
            <article>
              <h3 css={title}>categories/ </h3>
              <ul css={ul}>
                {categoryTags?.map((tag, index) => {
                  return (
                    <li key={index}>
                      <CategoryTag category={tag} />
                    </li>
                  )
                })}
              </ul>
            </article>
            <article>
              <Banner />
            </article>
          </div>
        </Wrapper>
      </Layout>
    </>
  )
}

const ul = css`
  list-style: none;
  margin-left: 1.5rem;
`

const title = css`
  font-weight: 700;
  text-transform: capitalize;
  color: var(--clr-grey-5);
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 3rem;
`

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--maxWidth-wrapper);
  margin: 0 auto;
  margin-bottom: 4rem;
  .section-center {
    display: grid;
    row-gap: 10rem;
  }
  @media (min-width: 1170px) {
    .section-center {
      display: grid;
      grid-template-columns: 1fr 150px;
      column-gap: 10rem;
    }
  }
`

export default CategoriesPage
