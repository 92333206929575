import React from "react"
import { Link } from "gatsby"

const Links = ({ styleClass, children }) => {
  return (
    <ul className={styleClass}>
      {styleClass === "nav-links" ? (
        ""
      ) : (
        <>
          <li>
            <Link to="/" className="page-link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/categories" className="page-link">
              Categories
            </Link>
          </li>
        </>
      )}
    </ul>
  )
}

export default Links
